import config from 'config';
import { getThumbnailPath } from '@vue-storefront/core/helpers';

import type { CartItem } from '../types/CartItem';

const productThumbnailPath = (product: CartItem, ignoreConfig = false) => {
  let thumbnail =
    product.thumbnail && product.thumbnail !== 'no_selection'
      ? product.thumbnail
      : product.image && product.image !== 'no_selection'
      ? product.image
      : '';

  if (
    product.type_id &&
    product.type_id === 'configurable' &&
    'configurable_children' in product &&
    product.configurable_children.length &&
    (ignoreConfig || !product.is_configured) &&
    'image' in product.configurable_children[0]
  ) {
    thumbnail = product.configurable_children[0].image;

    if (!thumbnail || thumbnail === 'no_selection') {
      const childWithImg = product.configurable_children.find((f) => f.image && f.image !== 'no_selection');

      if (childWithImg) {
        thumbnail = childWithImg.image;
      } else {
        thumbnail =
          product.thumbnail && product.thumbnail !== 'no_selection'
            ? product.thumbnail
            : product.image && product.image !== 'no_selection'
            ? product.image
            : '';
      }
    }
  }

  return thumbnail;
};

const getThumbnailForProduct = (product: CartItem): string => {
  const thumbnail = productThumbnailPath(product);

  if (typeof navigator !== 'undefined' && !navigator.onLine) {
    return getThumbnailPath(thumbnail, config.products.thumbnails.width, config.products.thumbnails.height);
  }

  return getThumbnailPath(thumbnail, config.cart.thumbnails.width, config.cart.thumbnails.height);
};

export default getThumbnailForProduct;
