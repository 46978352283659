import { onlineHelper } from '@vue-storefront/core/helpers';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers';
import { Component, BaseComponent, Prop, Watch } from '@zento-lib/components';
import { MicrocartProduct } from 'theme/stores/cart/components/Product';
import { Form } from 'theme/components/Form/Form';
import { NumericInput } from 'theme/components/Form/NumericInput';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { CheckoutPaymentStore } from 'theme/stores/checkoutPayment/checkoutPayment';

import { AddToWishlist } from '../../AddToWishlist/AddToWishlist';
import { EditButton } from '../Edit/Button';
import type { FilterType } from '../../types/FilterType';
import { Labels } from '../../../molecule/ProductLabels/Labels/Labels';
import { Link } from '../../../atom/Link/Link';
import { MainImage } from '../../../atom/MainImage/MainImage';
import { CartProductOptions } from '../../../atom/Cart/Product/Options/Options';
import { CartProductPrice } from '../../../atom/Cart/Product/Price/Price';
import { CartProductError } from '../../../atom/Cart/Product/Error/Error';
import { CartRemoveItem } from '../../../atom/Cart/Product/Remove/Remove';
import { InnerHTML } from '../../../atom/InnerHTML';

import type { ICartProduct } from './Product.d';
import style from './style.scss';

interface ICartProductMixin {
  thumbnail: string;
}

// Defines the different style types for MSRP price display
export type MsrpPriceDisplay = 'cart' | 'checkout';

/**
 * Cart Product
 *
 * Renders information for each cart product (image, title, attributes, quantity, etc.)
 */
@Component({
  mixins: [MicrocartProduct],
})
@Form()
export class CartProduct extends BaseComponent<ICartProduct, ICartProductMixin> {
  private static T = {
    cartProductSkuLabel: 'product_sku_label',
    cartProductRemoveBtn: 'cart_product_remove_btn_label',
    removeCartProductMsg: 'remove_cart_product_msg',
    rejectActionMsg: 'cancel_action_msg',
    resolveActionMsg: 'cart_resolve_action_msg',
  };

  /**
   * Current cart product
   */
  @Prop({ type: Object, required: true })
  product: IProductState;

  /**
   * Determines if quantity input manipulation has button controls
   */
  @Prop({ type: Boolean, default: true })
  controls?: boolean;

  /**
   * Determines if wishlist has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: false })
  customStyle?: boolean;

  /**
   * Determines if wishlist has some custom styles
   */
  @Prop({ type: Boolean, required: false, default: true })
  editSection?: boolean;

  /**
   * Determines MSRP price display
   */
  @Prop({ type: String, default: '' })
  displayMsrp?: MsrpPriceDisplay;

  /**
   * Free gift product sku
   */
  @Prop({ type: String, default: '' })
  freeGiftProductSku?: string;

  protected checkoutPaymentStore = new CheckoutPaymentStore();

  @Watch('product.qty', { deep: true })
  updateQuantity(newQty: number, oldQty: number) {
    const token = this.$store.state.cart.cartServerToken;

    if (this.product.qty > 1) {
      this.$data.productHasLowQty = false;
    } else {
      this.$data.productHasLowQty = true;
    }

    this.$data.isQtyUpdating = true;

    this.$store
      .dispatch('cart/updateQuantity', { product: this.product, qty: this.product.qty })
      .then(async (resp) => {
        if (resp.serverResponses.length) {
          const res = resp.serverResponses[0];

          this.$parent.$emit('cart/checkout-disabled', {
            status: res?.status,
            result: res?.result?.result,
          });

          this.checkoutPaymentStore.zentoPaymentMethods(this.checkoutPaymentStore.generateInvoice, token);

          if (this.freeGiftProductSku) {
            await this.$store.dispatch('cart/syncFreeGift');
          }

          if (this.extended.$config.zento.analytics.customGTM) {
            if (newQty > oldQty) {
              this.broadcast('analytics/add-to-cart', { product: this.product });
              this.broadcast('analytics/add-to-cart-custom', { product: this.product });
            } else {
              this.broadcast('analytics/remove-cart-product', { product: this.product });
              this.broadcast('analytics/remove-cart-product-custom', { product: this.product });
            }
          }
        }
      })
      .catch((err) => console.error(`Cart product update qty: ${err}`))
      .finally(() => {
        this.$data.isQtyUpdating = false;
      });
  }

  data() {
    return {
      productHasLowQty: true,
      aboutToRemove: false,
      isQtyUpdating: false,
    };
  }

  beforeMount() {
    this.closeCart = this.closeCart.bind(this);
    this.removeCartItem = this.removeCartItem.bind(this);
    this.checkoutPaymentStore.generateInvoice = false;

    if (this.product.qty > 1) {
      this.$data.productHasLowQty = false;
    } else {
      this.$data.productHasLowQty = true;
    }
  }

  get image() {
    return {
      loading: this.extended.thumbnail,
      src: this.extended.thumbnail,
      id: {} as FilterType,
    };
  }

  get isOnline() {
    return onlineHelper.isOnline;
  }

  productLink(product: IProductState | any) {
    return formatProductLink(product, '');
  }

  /**
   * Opens a popup for cart item removal if double check is enabled in config file
   */
  removeItem() {
    if (this.extended.$config.cart.askBeforeRemoveProduct) {
      this.$data.aboutToRemove = true;
    } else {
      this.removeCartItem();
    }
  }

  /**
   * Close cart
   */
  closeCart() {
    this.$store.commit('ui/setMicrocart', false);
    document.body.style.overflow = 'visible';
  }

  /**
   * Handler for removing a product from cart
   */
  async removeCartItem() {
    try {
      const diffLog = await this.$store.dispatch('cart/removeItem', { product: this.product });

      if (diffLog) {
        if (this.freeGiftProductSku) {
          await this.$store.dispatch('cart/syncFreeGift');
        }

        // Make sure checkout button is enabled after product remove
        this.$parent.$emit('cart/checkout-disabled', { status: 200 });

        if (diffLog.clientNotifications && diffLog.clientNotifications.length > 0) {
          diffLog.clientNotifications.forEach((notificationData) => {
            this.notifyUser(notificationData);
          });
        }
      }

      this.broadcast('analytics/remove-cart-product', { product: this.product });
      this.broadcast('analytics/remove-cart-product-custom', { product: this.product });

      return diffLog;
    } catch (err) {
      this.notifyUser({
        type: 'error',
        message: err,
        action1: { label: this.getTranslation({ id: 'notification_action' }) },
      });

      return null;
    }
  }

  private notifyUser(notificationData) {
    this.$store.dispatch('notification/spawnNotification', notificationData, { root: true });
  }

  get cartThumbnails() {
    return this.extended.$config.zento.images.cartThumbnails;
  }

  render() {
    const product = this.product;
    const stock = this.product.stock;
    const minQty = stock ? (parseFloat(stock.min_sale_qty) > 0 ? parseFloat(stock.min_sale_qty) : 1) : 1;
    const maxQty = stock ? (parseFloat(stock.max_sale_qty) > 0 ? parseFloat(stock.max_sale_qty) : Infinity) : Infinity;

    return (
      <li class={{ [style.cartProduct]: true, [style.cartProductCustom]: this.customStyle }}>
        {this.$data.isQtyUpdating ? (
          <div class={{ [style.isQtyUpdating]: this.$data.isQtyUpdating }} key='qty-is-updating' />
        ) : null}

        {product.errors && Object.keys(product.errors).length > 0
          ? [
              <div class={style.productError} key='product-error' />,
              <CartProductError errors={product.errors} key='cart-product-errors' />,
            ]
          : null}

        <div class={style.cartProductWrapper} key='cart-product-wrapper'>
          <div onClick={this.closeCart} class={style.cartProductImg} key='cart-product-img'>
            <Labels product={product} badgePosition='initial' class={style.badge} key='product-labels' />

            {product.sku !== this.freeGiftProductSku ? (
              <Link to={this.productLink(product)}>
                <MainImage
                  image={this.image}
                  alt={product.name}
                  width={this.cartThumbnails?.width}
                  height={this.cartThumbnails?.height}
                  tabletWidth={this.cartThumbnails?.tabletWidth}
                  tabletHeight={this.cartThumbnails?.tabletHeight}
                  desktopWidth={this.cartThumbnails?.desktopWidth}
                  desktopHeight={this.cartThumbnails?.desktopHeight}
                />
              </Link>
            ) : (
              <MainImage
                image={this.image}
                alt={product.name}
                width={this.cartThumbnails?.width}
                height={this.cartThumbnails?.height}
                tabletWidth={this.cartThumbnails?.tabletWidth}
                tabletHeight={this.cartThumbnails?.tabletHeight}
                desktopWidth={this.cartThumbnails?.desktopWidth}
                desktopHeight={this.cartThumbnails?.desktopHeight}
              />
            )}
          </div>

          <div class={style.cartProductInfoWrapper} key='cart-product-info-wrapper'>
            <div class={style.cartProductInfo} key='cart-product-info'>
              <div onClick={this.closeCart} class={style.cartProductName} key='cart-product-name'>
                {product.sku !== this.freeGiftProductSku ? (
                  <Link to={this.productLink(product)} styleType='secondary' key='cart-product-name'>
                    <InnerHTML contents={product.name} />
                  </Link>
                ) : (
                  <InnerHTML contents={product.name} />
                )}
                <span class={style.cartProductSku} data-testId='cartProductSku' key='cart-product-sku'>
                  {this.getTranslation({ id: CartProduct.T.cartProductSkuLabel })}:
                  <span>
                    {product.cod_furnizor && product.cod_furnizor.length
                      ? product.cod_furnizor + product.sku
                      : product.sku}
                  </span>
                </span>
                <CartProductOptions product={product} isOnline={this.isOnline} key='cart-product-options' />
                {this.customStyle && product.sku !== this.freeGiftProductSku ? (
                  <CartProductPrice
                    product={product}
                    displayMsrp={this.displayMsrp}
                    isOnline={this.isOnline}
                    class={{ [style.cartProductPriceCustom]: this.customStyle }}
                    key='cart-product-price'
                  />
                ) : null}
              </div>

              {!this.customStyle && product.sku !== this.freeGiftProductSku ? (
                <CartProductPrice
                  product={product}
                  displayMsrp={this.displayMsrp}
                  isOnline={this.isOnline}
                  key='cart-product-price'
                />
              ) : null}
            </div>

            {!this.$store.state.checkout.isThankYouPage && product.sku !== this.freeGiftProductSku ? (
              !this.customStyle && this.editSection ? (
                <div class={style.cartProductQtyWrapper} key='cart-product-qty-wrapper'>
                  <NumericInput
                    state={product}
                    valueKeeper='qty'
                    name='qty'
                    required={true}
                    step={
                      stock && stock.qty_increments && parseFloat(stock.qty_increments) >= 1
                        ? parseFloat(stock.qty_increments)
                        : 1
                    }
                    min={minQty}
                    max={maxQty}
                    validateOn='input'
                    validation={false}
                    controls={this.controls}
                    showDecrement={product.qty > 1}
                    slotPosition={false}
                    cartProduct={true}
                    class={style.cartProductNumericBtn}
                    key='input-controls'
                  />

                  {this.$data.productHasLowQty && product.qty === 1 ? (
                    <span
                      onClick={this.removeItem}
                      data-label={this.getTranslation({ id: CartProduct.T.cartProductRemoveBtn })}
                      class={style.cartProductBtnRemove}
                      data-testid='cartRemoveProduct'
                      key='cart-product-remove-btn'
                    />
                  ) : null}
                </div>
              ) : null
            ) : null}
          </div>
        </div>

        {!this.$store.state.checkout.isThankYouPage && product.sku !== this.freeGiftProductSku ? (
          <div
            class={{
              [style.cartProductBtns]: true,
              [style.productQtyCheck]: this.$data.productHasLowQty && product.qty === 1,
              [style.cartProductBtnsCustom]: this.customStyle,
            }}
            key='cart-product-btns-wrapper'>
            {this.customStyle && this.editSection ? (
              <div
                class={{ [style.cartProductQtyWrapper]: true, [style.cartProductQtyWrapperCustom]: this.customStyle }}
                key='cart-product-qty-wrapper'>
                <NumericInput
                  state={product}
                  valueKeeper='qty'
                  name='qty'
                  required={true}
                  step={
                    stock && stock.qty_increments && parseFloat(stock.qty_increments) >= 1
                      ? parseFloat(stock.qty_increments)
                      : 1
                  }
                  min={minQty}
                  max={maxQty}
                  validateOn='input'
                  validation={false}
                  controls={this.controls}
                  showDecrement={product.qty > 1}
                  slotPosition={false}
                  cartProduct={true}
                  class={style.cartProductNumericBtn}
                  key='input-controls'
                />

                {this.$data.productHasLowQty && product.qty === 1 ? (
                  <span
                    onClick={this.removeItem}
                    data-label={this.getTranslation({ id: CartProduct.T.cartProductRemoveBtn })}
                    class={style.cartProductBtnRemove}
                    data-testid='cartRemoveProduct'
                    key='cart-product-remove-btn'
                  />
                ) : null}
              </div>
            ) : null}
            {!this.$data.productHasLowQty && product.qty > 1 && this.editSection ? (
              <span
                onClick={this.removeItem}
                data-label={this.getTranslation({ id: CartProduct.T.cartProductRemoveBtn })}
                class={{ [style.cartProductBtnRemove]: true, [style.cartProductBtnRemoveCustom]: this.customStyle }}
                data-testid='cartRemoveProduct'
                key='cart-product-remove-btn'>
                {this.getTranslation({ id: CartProduct.T.cartProductRemoveBtn })}
              </span>
            ) : null}

            <EditButton product={product} key='cart-product-edit-mode' />

            <span
              class={{ [style.cartProductBtnWishlist]: true, [style.cartProductBtnWishlistCustom]: this.customStyle }}
              key='cart-product-add-to-wishlist'>
              <AddToWishlist product={product} cartProduct={true} />
            </span>
          </div>
        ) : null}

        {this.$data.aboutToRemove && product.sku !== this.freeGiftProductSku ? (
          <CartRemoveItem
            rejectFn={() => {
              this.$data.aboutToRemove = false;
            }}
            resolveFn={this.removeCartItem}
            removeCartProductMsg={{ id: CartProduct.T.removeCartProductMsg }}
            rejectActionMsg={{ id: CartProduct.T.rejectActionMsg }}
            resolveActionMsg={{ id: CartProduct.T.resolveActionMsg }}
            key='cart-product-remove'
          />
        ) : null}
      </li>
    );
  }
}
