import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { I18nMessage } from '@zento-lib/components/Base/types';

import type { ICartRemoveItem } from './Remove.d';
import style from './style.scss';

/**
 * Popup for cart remove item
 **/
@Component({})
export class CartRemoveItem extends BaseComponent<ICartRemoveItem, unknown> {
  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   * In this case it will cancel the intention to remove the product
   */
  @Prop({ type: Function, default: () => undefined })
  rejectFn: (ev: Event) => void;

  /**
   * An event that will carry out the process when the conditions inherent to the event they're associated with, are met
   * In this case it will remove the product from cart
   */
  @Prop({ type: Function, default: () => undefined })
  resolveFn: (ev: Event) => void;

  /**
   * A text representing cart item popup remove message info
   */
  @Prop({ type: Object, required: true })
  removeCartProductMsg: I18nMessage;

  /**
   * A text representing cart item cancel button label
   */
  @Prop({ type: Object, required: true })
  rejectActionMsg: I18nMessage;

  /**
   * A text representing cart item remove button label
   */
  @Prop({ type: Object, required: true })
  resolveActionMsg: I18nMessage;

  render() {
    return (
      <div class={style.notificationsWrapper}>
        <div class={style.notification}>
          <div class={style.message}>{this.getTranslation(this.removeCartProductMsg)}</div>
          <div class={style.actions}>
            <div onClick={this.rejectFn} class={style.notificationBtn} data-testid='abort'>
              {this.getTranslation(this.rejectActionMsg)}
            </div>
            <div onClick={this.resolveFn} class={style.notificationBtn} data-testid='resolve'>
              {this.getTranslation(this.resolveActionMsg)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
