import { Component, BaseComponent, Prop } from '@zento-lib/components';
import { FormatCurrency } from '@zento-lib/components/i18n/FormatCurrency';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { StoreConfig } from 'theme/stores/storeConfig/storeConfig';

import { ProductPrice } from '../../../Product/Price/Price';

import type { ICartProductPrice } from './Price.d';
import style from './style.scss';

// Defines the different style types for MSRP price display
export type MsrpPriceDisplay = 'cart' | 'checkout';

/**
 * Cart Price
 **/
@Component({})
export class CartProductPrice extends BaseComponent<ICartProductPrice, unknown> {
  protected static T = {
    productPriceInclLabel: 'product_price_incl_label',
    productPriceExclLabel: 'product_price_excl_label',
  };

  /**
   * Current cart product
   */
  @Prop({ type: Object, required: true })
  product: IProductState;

  @Prop({ type: Boolean, required: true })
  isOnline: boolean;

  /**
   * Determines MSRP price display
   */
  @Prop({ type: String, default: '' })
  displayMsrp?: MsrpPriceDisplay;

  private storeConfigData = new StoreConfig();

  render() {
    return this.isOnline && (this.product.totals || this.product.price) ? (
      <ProductPrice
        product={this.product}
        displayMsrp={this.displayMsrp}
        qtyIncrement={true}
        cartQtyIncrement={true}
        class={style.cartProductPrice}
        productPriceInclLabel={{ id: CartProductPrice.T.productPriceInclLabel }}
        productPriceExclLabel={{ id: CartProductPrice.T.productPriceExclLabel }}
        cartTaxdisplayType={this.storeConfigData.storeConfigurations.taxCartDisplayPrice}
      />
    ) : (
      <div class={style.cartProductPrice}>
        <FormatCurrency
          value={this.product.regular_price * (this.product.qty || 1)}
          class={style.cartProductRegularPrice}
        />
      </div>
    );
  }
}
