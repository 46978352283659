import { getThumbnailForProduct, getProductConfiguration } from '../helpers';

export const MicrocartProduct = {
  name: 'MicrocartProduct',

  props: {
    product: {
      type: Object,
      required: true,
    },
  },

  computed: {
    thumbnail() {
      return getThumbnailForProduct(this.product);
    },

    configuration() {
      return getProductConfiguration(this.product);
    },
  },

  methods: {
    removeFromCart() {
      this.$store.dispatch('cart/removeItem', { product: this.product });
    },

    updateQuantity(quantity) {
      this.$store.dispatch('cart/updateQuantity', { product: this.product, qty: quantity });
    },
  },
};
