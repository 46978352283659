import { Component, BaseComponent, namespace, Prop } from '@zento-lib/components';
import { IVSFStoreProduct } from 'theme/@types/vsf/stores/product';
import { IExtendCart, KEY as ExtendCartKey } from 'theme/@types/zento/stores/cartExtend';
import { getCurrentConfig } from 'theme/stores/extend-cart/helpers/getCurrentConfig';

import type { IEditButton } from './Button.d';
import style from './style.scss';

const cartExtendStore = namespace<IExtendCart>(ExtendCartKey);

/**
 * Cart Product Edit Button
 *
 * Adds a button which will enable cart product editing
 */
@Component({})
export class EditButton extends BaseComponent<IEditButton, unknown> {
  private static T = {
    cartEdit: 'cart_summary_edit',
  };

  /**
   * Current cart product
   */
  @Prop({ type: Object, required: true })
  product: IVSFStoreProduct['state']['current'];

  @cartExtendStore.Getter('getEditingProductId')
  private getEditingProductId: IExtendCart['state']['editMode']['product']['id'];

  beforeMount() {
    this.openEditMode = this.openEditMode.bind(this);
  }

  render() {
    return (
      <div class={style.editWrapper}>
        {this.productsAreReconfigurable && !this.editMode ? (
          <span
            onClick={this.openEditMode}
            data-label={this.getTranslation({ id: EditButton.T.cartEdit })}
            class={style.openEditModeBtn}
            key='open-edit-mode'>
            {this.getTranslation({ id: EditButton.T.cartEdit })}
          </span>
        ) : null}
      </div>
    );
  }

  /**
   * Determines if current cart product is editable
   */
  private get productsAreReconfigurable() {
    return this.product.type_id === 'configurable';
  }

  /**
   * Determines if current cart product is in edit mode or not
   */
  private get editMode() {
    return this.getEditingProductId === this.product.id;
  }

  /**
   * Returns configuration for current cart product
   */
  private get swatchConfiguration() {
    return getCurrentConfig(this.product);
  }

  /**
   * Configures current cart product for edit
   */
  private openEditMode() {
    this.$store.dispatch('extendCart/configureProduct', { product: this.product }).then(() => {
      const selectedOptions = Object.assign({}, this.swatchConfiguration);
      this.$store.dispatch('extendCart/openEditMode', { product: this.product, selectedOptions });
    });
  }
}
