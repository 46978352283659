import { AsVuexStore } from '@zento-lib/components/vuex';

import type { CartState } from '../../../stores/extend-cart/types/CartState';

export const KEY = 'extendCart';

export type IExtendCart = AsVuexStore<
  CartState,
  'isEditMode' | 'getEditingProduct' | 'getEditingProductId' | 'getSelectedOptions' | 'getEditingQty',
  'configureProduct' | 'openEditMode' | 'editModeSetFilters' | 'editModeSetQty' | 'closeEditMode',
  'cart/CART_OPEN_EDIT_MODE' | 'cart/CART_EDIT_MODE_SET_FILTERS' | 'cart/CART_EDIT_QTY' | 'cart/CART_CLOSE_EDIT_MODE'
>;
