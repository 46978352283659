import { Component, BaseComponent, Prop } from '@zento-lib/components';

import type { ICartProductError } from './Error.d';
import style from './style.scss';

/**
 * Cart Product Error
 *
 * Renders errors per cart item
 **/
@Component({})
export class CartProductError extends BaseComponent<ICartProductError, unknown> {
  /**
   * An object containing cart product errors
   */
  @Prop({ type: Object, required: true })
  errors: any;

  render() {
    return this.errors && Object.keys(this.errors).length > 0 ? (
      <div class={style.cartProductErrors}>{this.errors.stock}</div>
    ) : null;
  }
}
