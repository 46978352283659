import { Component, BaseComponent, Prop } from '@zento-lib/components';
import type { IProductState } from 'theme/@types/vsf/stores/product';
import { InnerHTML } from '@zento/modules/atom/InnerHTML';

import type { ICartProductOptions } from './Options.d';
import style from './style.scss';

/**
 * Cart Options
 *
 * Renders cart configurable product custom options (like color, size, etc.)
 **/
@Component({})
export class CartProductOptions extends BaseComponent<ICartProductOptions, unknown> {
  /**
   * Current cart product
   */
  @Prop({ type: Object, required: true })
  product: IProductState;

  @Prop({ type: Boolean, required: true })
  isOnline: boolean;

  render() {
    const hideCartOptionPrice = this.extended.$config.zento.cart.hideCartOptionPrice;

    return (
      <div class={style.cartProductOptions}>
        {this.isOnline && this.product.totals && this.product.totals.options
          ? this.product.totals.options.map((option: Record<string, any>, i: number) => {
              return (
                <div key={option.label + '-' + i}>
                  {/* TODO: option.label should be the label for attribute from admin panel, not the code */}
                  <span>{this.getTranslation({ id: option.label })}</span>:
                  <span
                    class={{
                      [style.cartProductOptionsValue]: true,
                      [style.hideCartOptionPrice]: hideCartOptionPrice,
                    }}>
                    <InnerHTML contents={option.value} />
                  </span>
                </div>
              );
            })
          : this.product.options
          ? this.product.options.map((option, i) => {
              return (
                <div key={option.labe + i}>
                  {/* TODO: option.label should be the label for attribute from admin panel, not the code */}
                  <span>{this.getTranslation({ id: option.label })}</span>:
                  <span
                    class={{
                      [style.cartProductOptionsValue]: true,
                      [style.hideCartOptionPrice]: hideCartOptionPrice,
                    }}>
                    <InnerHTML contents={option.value} />
                  </span>
                </div>
              );
            })
          : null}
      </div>
    );
  }
}
