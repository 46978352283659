import config from 'config';
import rootStore from '@vue-storefront/core/store';

import type { CartItem } from '../types/CartItem';
import type { ProductConfiguration } from '../types/ProductConfiguration';

import getProductOptions from './getProductOptions';

const getProductConfiguration = (product: CartItem): ProductConfiguration => {
  const options = getProductOptions(product);
  const getAttributesFields = (attributeCode) =>
    options[attributeCode].find((c) => c.id === parseInt(product[attributeCode]));
  const defaultFilters = Object.keys((rootStore.state.attribute as any).list_by_code).length
    ? Object.keys((rootStore.state.attribute as any).list_by_code)
    : config.zento.theme.category.defaultFilters.length
    ? config.zento.theme.category.defaultFilters
    : [];

  if (!options) {
    return null;
  }

  return (defaultFilters.reduce(
    (prev, curr) => ({
      ...prev,
      [curr]: {
        attribute_code: curr,
        ...getAttributesFields(curr),
      },
    }),
    {},
  ) as any) as ProductConfiguration;
};

export default getProductConfiguration;
